@keyframes bounceRotation {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

@keyframes bounceLeft {
  0%, 100% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-10px);;
  }
}
