// ****************************
// ****  Buttons ICONS  *******
// ****************************

button.icon,
.icon button {
  border: none;
  background-color: transparent;
  &:focus,
  &:active,
  &:visited {
    border: none;
    outline: none;
  }
}

.icon {
  margin: 5px;
  svg {
    width: 24px;
  }
  &:hover {
    cursor: pointer;
  }

  &.icon-like,
  &.icon-flip {
    position: absolute;
    padding-bottom: 3px;
    bottom: 0;
    @include media-breakpoint-down(sm) {
      fill: white;
    }
  }

  // ICON LIKE
  &.icon-like {
    opacity: 0;
    left: 0;
    padding-left: 5px;
    svg {
      transition: all 0.25s ease-in-out;
    }
    &:hover {
      opacity: 1;
      svg {
        transform: scale(1.25);
      }
      .help-text {
        opacity: 1;
        left: 40px;
        @include media-breakpoint-down(sm) {
          color: white;
        }
      }
    }
    svg path#contour {
      fill: white;
      stroke: $text-color;
      stroke-width: 30;
      stroke-miterlimit: 10;
    }
    &.like {
      svg path#contour {
        fill: $link-color;
        stroke: $link-color;
        stroke-width: 30;
        stroke-miterlimit: 10;
      }
      .help-text {
        display: none;
      }
    }
    .help-text {
      font-size: 0.9em;
      left: 50px;
      bottom: 0;
      position: absolute;
      opacity: 0;
      text-align: left;
      width: 70px;
      transition: all .25s ease-out;
    }
  }

  // ICON FLIP
  &.icon-flip {
    opacity: 0;
    right: 0;
    padding-right: 5px;
    svg {
      transform: rotate(0deg);
      transform-origin: 50% 50%;
      transition: all 0.25s ease-in-out;
    }
    &:hover {
      opacity: 1;
      svg {
        transform: rotate(180deg);
      }
      .help-text {
        opacity: 1;
        right: 35px;
        @include media-breakpoint-down(sm) {
          color: white;
        }
      }
    }
    .help-text {
      font-size: 0.9em;
      right: 45px;
      bottom: 3px;
      position: absolute;
      opacity: 0;
      text-align: right;
      width: 70px;
      transition: all .25s ease-out;
    }
  }

  // ICON FULLSCREEN
  &.icon-fullscreen {
    svg {
      transition: all 0.25s ease-in-out;
      transform: scale(1);
      &:hover {
        transform: scale(1.1);
      }
    }
    &.is-fullscreen {
     svg {
       &:hover {
         transform: scale(0.9);
       }
     }
    }
  }
  // ICON ZOOM
  &.icon-zoom {
    svg #image {
      transition: all 0.25s ease-in-out;
      transform-origin: 50% 50%;
      transform: scale(1);
    }
    &:hover,
    &.active {
      svg {
        #image {
          transform: scale(1.75);
        }
      }
    }
    &:hover.active {
      svg #image {
        transform: scale(1);
      }
    }
  }
}
