// Typography
$font-family-sans-serif: 'Open Sans Condensed', sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 20px;


// Colors
$body-bg: #f0f0f0;
$text-color: #212529;
$link-color: #c1272d;
$link-hover-color: darken($link-color, 15%);
$color-success: #008744;
$color-danger: #d33a2c;
$color-warning: #f48400;
$color-info: #17a2b8;
$color-primary: #006bb7;

:root {
  --blinking-delay: 4s;
}
