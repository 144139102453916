// FOOTER

footer {
  @include clearfix;
  background-color: #fff;
  border-top: 1px solid #e2e2e2;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  padding: 0.5rem 1rem;
  @include media-breakpoint-up(sm) {
    padding: 1rem 1rem 0.6rem;
  }

  nav {
    a {
      &:after {
        width: 35px;
        height: 35px;
        top: 35%;
      }
    }
    &#info {
      float: left;
    }
    &#social {
      float: right;
    }
  }
}


