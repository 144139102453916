// HEADER
header {
  @include clearfix;
  padding: 10px 20px 1px;
  text-align: center;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e2e2e2;

  // sticky header
  &.sticky {
    @include media-breakpoint-up(md) {
      position: fixed;
      top: 0;
      z-index: 100;
    }
  }
  + main {
    @include media-breakpoint-up(md) {
      padding-top: 72px;
    }
  }

  #utilities {
    width: 200px;
    text-align: right;
    display: block;
    float: right;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    ul {
      li {
        display: inline-block;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  nav#main {
    text-align: center;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
      text-align: right;
    }
    font-size: $h2-font-size*1.1;
    margin-left: 220px;
    margin-right: 110px;
    margin-bottom: -6px;
    padding-top: 6px;
    @include media-breakpoint-down(xs) {
      font-size: $h2-font-size*1;
      text-align: center;
      margin: 0;
      padding: 0;
      width: 100%;
      ul > li {
        margin: 10px 5px 0px;
      }
    }

    a {
      position: relative;
      display: inline;
      &::after {
        width: 50px;
        height: 50px;
        top: 36%;
        left: 50%;
        border-width: 5px;
      }
      @include media-breakpoint-down(xs) {
        padding: 0 0.1em;
      }
    }
  }
}

// help text on hover of an icon
.menu {
  ul li {
    position: relative;
  }
  .help-text,
  .help-text-active {
    font-size: 0.9em;
    bottom: -20px;
    position: absolute;
    opacity: 0;
    text-align: right;
    right: -3px;
    width: 200px;
    transition: all .25s ease-out;
    &.show {
      opacity: 1;
      bottom: -25px;
    }
  }
}
