// LAYOUT
html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  min-height: 100%;
  padding-bottom: 6rem;
  position: relative;
  @include media-breakpoint-up(sm) {
    background-image: url("../images/content-texture100.jpg");
    background-repeat: repeat;
  }
}

main {
  @include clearfix;
}

.intro {
  padding: 2em 2.5% 70px 2.5%;
  text-align: center;
  min-height: 60px;
  position: relative;
  overflow-x: hidden;
  z-index: 1;
  p {
    font-size: $font-size-base*1.1;
  }
  @include media-breakpoint-down(xs) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.container {
  width: 90%;
}

// NAVIGATION
nav {
  font-family: 'DIN Condensed Bold';
  text-transform: uppercase;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 0.5em;
    }
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
    padding: 20px 0 15px;
  }
}
