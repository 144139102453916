
.page-default {
  // No utilities in default pages
  header#navigation {
    div#utilities ul li {
      display: none;
    }
  }
  .intro {
    padding: 2em 2.5% 20px 2.5%;
  }
  main {
    margin-bottom: 5em;

    // images in richtext
    img {
      width: 100%;
      margin-bottom: 1em;
      border-radius: 5px;
      border: 2px solid white;
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.25);
    }
  }
}
