// LOGO
.logo {
  display: flex;
  float: left;
  position: relative;
  a {
    // Invert colors when hover
    &:hover {
      &::after {
        display: none;
      }
      .lastname {
        color: black;
      }
      .firstname {
        color: $link-color;
      }
      .square {
        transform: rotate(-100deg);
      }
    }
  }

  svg {
    float: left;
    height: 50px;
    padding-right: 10px;
    z-index: 1;
    position: relative;
  }
  .firstname,
  .lastname,
  .domain {
    font-family: 'DIN Condensed Bold';
  }
  .firstname,
  .lastname {
    float: left;
    font-size: 2.5rem;
    text-transform: lowercase;
    transition: all .25s;
  }
  .firstname {
    color: black;
  }
  .lastname {
    color: $link-color;
  }
  .domain {
    text-transform: uppercase;
    font-size: 1rem;
    position: absolute;
    left: 68px;
    top: 39px;
    color: black;
  }
  .square {
    width: 50px;
    height: 50px;
    border: 5px solid $link-color;
    position: absolute;
    left: 0px;
    z-index: 0;
    transform: rotate(-10deg);
    transition: all 0.25s ease-in-out;
  }

  @include media-breakpoint-down(xs) {
    align-items: center;
    justify-content: center;
    float: none;
    & > a {
      @include clearfix();
    }
  }
  &.logo-sm {
    .firstname,
    .lastname {
      font-size: 1.3rem;
    }
    .domain {
      font-size: 0.45rem;
      left: 38px;
      top: 21px;
    }
    .square {
      width: 25px;
      height: 25px;
      border: 3px solid $link-color;
    }
    svg,
    img {
      height: 25px;
      padding-right: 5px;
      z-index: 1;
    }
  }
}

.logo {
  &:hover {
    svg {
      #eyes {
        //fill: #ffffff;
      }
    }
  }
  svg {
    @keyframes eyesClosed {
      0%,48%,52% {transform: scaleY(1); }
      50% {transform: scaleY(0.5);}
    }
    #eyes {
      animation-name: eyesClosed;
      animation-duration: 4s;
      animation-delay: var(--blinking-delay);
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      transform-origin: center 41%;
    }
  }
}
