.page-home {
  .intro {
    padding-bottom: 40px;
    @include media-breakpoint-down(xs) {
      margin-bottom: 0px;
      padding-top: 0px;
    }
  }
  .albums {
    .album-container {
      @include media-breakpoint-down(xs) {
        margin-bottom: 50px;
      }
    }
  }
}

