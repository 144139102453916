.paper {
  position: relative;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin: 10px auto;
  border: 1px solid #eaeaea;
  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 10px;
    width: 40%;
    height: 10px;
    box-shadow: 0 5px 14px rgba(0,0,0,.7);
    z-index: -1;
    transition: all .3s ease-in-out;
  }
  &::before {
    left: 15px;
    transform: skew(-5deg) rotate(-5deg);
  }
  &::after {
    right: 15px;
    transform: skew(5deg) rotate(5deg);
    box-shadow: 0 2px 14px rgba(0,0,0,.4);
  }
  &:hover {
    &::before {
      box-shadow: 0 2px 14px rgba(0,0,0,.4);
    }
    &::before {
      left: 5px;
    }
    &::after {
      right: 5px;
    }
  }
}

// hint
.hint {
  text-align: left;
  padding: 10px;
  font-size: $font-size-base * 1;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  background-color: #fff;
  width: 300px;
  min-height: 80px;
  margin: 0 auto;
  position: absolute;
  right: -225px;
  top: 10px;
  transition: right .5s ease;
  &.show {
    right: -20px;
  }

  .hint-text {
    margin-left: 70px;
    margin-right: 20px;
    p {
      margin-bottom: 0;
    }
  }
  .closeHint {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: none;
    color: lightgray;
  }
  .bulb {
    position: absolute;
    width: 60px;
    padding: 0 10px;
  }
  &:hover {
    cursor: pointer;
    .closeHint {
      color: $link-color;
    }
    svg {
      transition: all 0.25s ease-in-out;
      animation-name: bounceRotation;
      animation-duration: .25s;
      animation-fill-mode: both;
      animation-timing-function: linear;
      .b {
        fill: #e9f15f;
      }
    }
  }
}
