.flickity-enabled.is-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.9);
  padding-bottom: 0;
  z-index: 1;
}

.flickity-enabled.is-fullscreen .flickity-page-dots {
  bottom: 10px;
}

.flickity-enabled.is-fullscreen .flickity-page-dots .dot {
  background: white;
}

/* prevent page scrolling when flickity is fullscreen */
html.is-flickity-fullscreen {
  overflow: hidden;
}

/* ---- flickity-fullscreen-button ---- */

.flickity-fullscreen-button {
  display: block;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.flickity-fullscreen-button {
  margin-top: 25px;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

/* right-to-left */
.flickity-rtl .flickity-fullscreen-button {
  right: auto;
  left: 10px;
}

.flickity-fullscreen-button-exit { display: none; }

.flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit { display: block; }
.flickity-enabled.is-fullscreen .flickity-fullscreen-button-view { display: none; }

.flickity-fullscreen-button .flickity-button-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 4px;
  top: 4px;
}

.flickity-viewport {
  transition: height 0.2s;
}

.is-fullscreen {
  width: 100%;
  .photo {
    height: 100%;
    /* center images in cells with flexbox */
    display: flex;
    max-width: none !important;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
    &.landscape {
      //width: 100%;
    }
    img {
      display: block;
      max-height: 100%;
    }
    .flip-card-inner {
      box-shadow: none;
    }
    .frame {
      padding: 0;
      border: none;
      box-shadow: none;
      .canvas {
        padding: 0;
        background-color: transparent;
        color: #fff;
      }
    }
  }
}

// Show next/previous buttons only on iPhone
@include media-breakpoint-up(md) {
  .flickity-prev-next-button {
    display: none;
  }
}

.flickity-prev-next-button {
  top: 46%;
  -webkit-transform: none;
  transform: none;
  width: 40px;
  height: 60px;
  border-radius: 5px;
  color: white;
  // background-color: rgba(0, 0, 0, 0.2) !important;
  // opacity: 0;
}

.album-selected .flickity-prev-next-button {
  // opacity: 0.75;
}

.flickity-prev-next-button.previous {
  right: auto;
  left: 12px;
}

.flickity-prev-next-button.next {
  left: auto;
  right: 12px;
}

.flickity-prev-next-button .flickity-button-icon {
  left: 25%;
  top: 25%;
  width: 50%;
  height: 50%;
}

.flickity-button {
  background: transparent;
  &:disabled {
    opacity: 0;
  }
  &:hover {
    background: transparent;
  }
}

// count cells
.album-selected {
  counter-reset: photo;
}
.photo-number {
  counter-increment: photo;
  &:before {
    content: counter(photo);
  }
}

.flickity-page-dots {
  bottom: 10px;
  z-index: 1;
  height: 5px;
  opacity: 0;

  &:hover {
    bottom: 0;
    height: 15px;
  }
  &:hover .dot {
    height: 15px;
    &:before {
      top: 4px;
    }
  }

}
/* dots are lines */
.flickity-page-dots .dot {
  height: 7px;
  width: 2.5%;
  margin: 0;
  border-radius: 0;
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: ease;
  background: $link-color;

  // hover
  &:hover {
    opacity: 0.5;
  }
  &.is-selected:hover,
  &.is-selected {
    opacity: 0.8;
  }

}

.album-selected {
  .flickity-page-dots {
    position: fixed;
    opacity: 1;
    width: 100%;
  }
}




