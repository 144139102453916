// *************
// TYPOGRAPHY
// *************

// FONTS
@import url('https://fonts.googleapis.com/css?family=Lacquer|Open+Sans+Condensed:300&display=swap');
@font-face {
  font-display: swap;
  font-family: 'DIN Condensed Bold';
  font-style: normal;
  font-weight: bold;
  src: local('DIN Condensed Bold'), url('../fonts/DINCondensed-Bold.woff') format('woff');
}

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  font-family: 'DIN Condensed Bold';
  text-transform: uppercase;
}
h1, .h1 {
  font-size: $h1-font-size * 1.7;
  margin-top: 2rem;
  line-height: 2rem;
  @include media-breakpoint-down(sm) {
    font-size: $h1-font-size * 1;
  }
  a {
    &::after {
      width: 80px;
      height: 80px;
      top: 33%;
      border-width: 9px;
    }
  }
}
h2, .h2 {
  font-size: $h2-font-size * 1.6;
  @include media-breakpoint-down(sm) {
    font-size: $h2-font-size * 1;
  }
  a {
    &::after {
      width: 70px;
      height: 70px;
      top: 33%;
      border-width: 7px;
    }
  }
}
h3, .h3 {
  margin-top: 5rem;
  margin-bottom: 2rem;
  overflow: hidden;
  font-size: $h3-font-size * 1.5;
  @include media-breakpoint-down(sm) {
    font-size: $h3-font-size * 1;
  }
  &::before,
  &::after {
    background-color: $text-color;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: super ;
    width: 50%;
  }
  &::before {
    right: 0.33em;
    margin-left: -50%;
  }
  &::after {
    left: 0.33em;
    margin-right: -50%;
  }

}
h4, .h4 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: $h4-font-size * 1.3;
  @include media-breakpoint-down(sm) {
    font-size: $h4-font-size * 1;
  }
}

h5, .h5 {
  margin-top: 2.5rem;
  margin-bottom: 0rem;
  font-size: $h5-font-size * 1.25;
  @include media-breakpoint-down(sm) {
    font-size: $h5-font-size * 1;
  }
}

h6, .h6 {
  margin-top: 2rem;
  margin-bottom: 0rem;
  font-size: $h6-font-size * 1.2;
  @include media-breakpoint-down(sm) {
    font-size: $h6-font-size * 1;
  }
}

// LINKS
a,
button {
  transition: all 0.25s ease-in-out;
}
a {
  position: relative;
  display: inline;

  &::after {
    width: 30px;
    height: 30px;
    transform: translateX(-50%) translateY(-50%) scale(0.8) rotate(8deg);
    position: absolute;
    top: 50%;
    left: 50%;
    border-width: 5px;
    border-color: $link-color;
    border-style: solid;
    content: '';
    opacity: 0;
    transition: all 0.25s ease-in-out;
  }

  &:hover,
  &[aria-current="page"] {
    outline: none;
    text-decoration: none;
    color: darken($link-color, 10%);
    &::after {
      transition: all 0.25s ease-in-out;
      opacity: 0.2;
      transform: translateX(-50%) translateY(-50%) scale(1) rotate(-8deg);
      animation-duration: .25s;
      animation-fill-mode: both;
      animation-timing-function: linear;
      // animation-iteration-count: infinite;
    }
  }
  &[aria-current="page"]:hover::after {
    animation-name: bounceLink;
  }
}

@keyframes bounceLink {
  0%, 100% {
    transform: translateX(-50%) translateY(-50%) scale(1) rotate(-8deg);
  }
  50% {
    transform: translateX(-50%) translateY(-50%) scale(1) rotate(-16deg);
  }
}

.text-center {
  text-align: center;
}


// Abbreviation
abbr[title],
abbr[data-original-title] {
  &.asterisk {
    text-decoration: none;
    color: $link-color;

  }
}
abbr[title] {
  position: relative;
  &:hover,
  &:focus {
    outline-style: none;
    &::after {
      text-transform: initial;
      font-family: $font-family-base;
      content: attr(title);

      /* position tooltip like the native one */
      position: absolute;
      transform: translateX(-50%);
      top: -2.2rem;
      width: auto;
      white-space: nowrap;

      /* style tooltip */
      background-color: $text-color;
      color: #fff;
      border-radius: 4px;
      box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
      font-size: $h4-font-size/1.5;
      padding: 0 7px;
      line-height: 1.8rem;
    }

  }
}
