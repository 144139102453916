// Album Design
$frame: 15px;
$canvas: 12.5%;
$gap: 10%;

figure {
  margin: 0;
  figcaption {
    text-align: center;
  }
}


// flickity
.albums {
  margin-top: -100px; // for the flip animation to be seen
  position: relative;
  @include media-breakpoint-down(sm) {
    margin-top: -130px;
  }
  .album-title {
    margin-top: 20px;
    position: absolute;
    width: 100%;
    text-align: center;
    @include media-breakpoint-down(sm) {
      margin-top: 40px;
      padding: 0 50px;
    }
  }
  .album-container {
    position: relative;
    margin-bottom: 10px;
    @include media-breakpoint-down(xs) {
      margin-bottom: 0px;
    }
    .next-album {
      z-index: 10;
      text-align: center;
      width: 100%;
      position: absolute;
      top: -400px;
    }
  }
}

// PHOTO
.photo {
  position: relative;
  transition-property: width, height;
  transition-duration: .5s;
  transition-timing-function: ease;
  z-index: 2;
  margin-right: 10%;
  margin-top: 90px; // for the flip animation to be seen
  margin-bottom: 90px; // for the flip animation to be seen
  max-width: 800px; // hero image

  &.portrait {
    width: 40%;
    max-width: 600px; // image will be 600*75% - border:30px - passpartout:6px = 424px
    @include media-breakpoint-down(md) {
      width: 50%;
    }
  }
  &.landscape {
    width: 60%;
    max-width: 1000px; // image will be 1000*75% - border:30px - passpartout:6px = 724px
    @include media-breakpoint-down(md) {
      width: 75%;
    }
    .photo-caption {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }

  &.portrait,
  &.landscape {
    @include media-breakpoint-down(sm) {
      width: 95%;
    }
  }

  .photo-sticker {
    display: block;
    float: left;
    text-align: left;
    @include clearfix();
    position: absolute;
  }

  .photo-logo {
    float: none;
    @include clearfix();
  }

  .signature {
    margin-top: 5px;
    img {
      width: 50%;
    }
  }

  .photo-info {
    padding: 20px;
    line-height: 1.5rem;
    text-align: center;

    // On computers, show like it's a sticker
    @include media-breakpoint-up(md) {
      background-color: rgba(240, 240, 240, 0.9);
      box-shadow: 0 1px 3px rgba(0,0,0,0.25);
      border-radius: 2px;
      left: 50%;
      transform: translateX(-50%) rotate(0deg) scale(1);
      max-width: 50%;
    }



    // Only iPhone
    @include media-breakpoint-down(sm) {
      width: 100%;
      color: #fff;
      padding: 30px;
    }

    .photo-title {
      font-weight: bold;
      font-size: $font-size-base*1;
      margin-bottom: 0.5em;
    }

    .photo-caption {
      font-size: $font-size-base * 0.9;
    }
  }

  .photo-metadata {
    @include media-breakpoint-up(md) {
      background-color: rgba(240, 240, 240, 0.9);
      color: $text-color;
      box-shadow: 0 1px 3px rgba(0,0,0,0.25);
    }
    border-radius: 2px;
    color: white;
    bottom: 5px;
    padding: 5px 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    max-width: 300px;

    .photo-number {
      font-weight: bold;
      float: right;
      font-size: $font-size-base*0.6;
    }
  }

  .photo-location {
    font-weight: bold;
    font-size: $font-size-base * 0.75;
    margin: 0;
    padding: 0;
    // Only iPhone
    @include media-breakpoint-down(xs) {
      font-size: $font-size-base * 0.66;
    }
  }
  ul.photo-exif {
    margin-bottom: 0;
    opacity: .5;
    @include media-breakpoint-down(xs) {
      // display: none;
    }
    .photo-date {
      font-weight: bold;
    }
    font-size: $font-size-base * 0.55;
    padding-left: 0;
    li {
      display: inline-block;
      &:not(:first-child)::before {
        content: ' • ';
      }
    }
  }

  &.is-selected {
    .frame {
      cursor: initial;
      cursor: -webkit-grab;
      cursor: grab;
    }
    .icon-flip,
    .icon-like {
      opacity: 0.25;
    }
    .icon-like.like {
      opacity: 1;
    }
  }

  .frame-zoom {
    .icon {
      stroke: white;
      svg,
      svg path#contour {
        stroke: white;
        fill: white;
      }
      .help-text {
        color: white;
      }
    }
  }
}

.frame {
  cursor: pointer;
  transition: all .5s ease;
  border-top: $frame solid #111;
  border-left: $frame solid #222;
  border-right: $frame solid #222;
  border-bottom: $frame solid #111;
  border-radius: 2px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  top: 0;
  position: relative;
  z-index: 2;
  @include media-breakpoint-down(sm) {
    border: 3px solid #111;
  }
  img {
    height: auto;
    width: 100%;
  }
  .canvas {
    background-color: #fff;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.5);
    padding: $canvas;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    img {
      border-top: 2px solid #ccc;
      border-left: 2px solid #ccc;
      border-right: 2px solid #ddd;
      border-bottom: 2px solid #ddd;
      background-color: #b9b9b9;
      background-size: 243px 113px;
      background-position: center;
      background-repeat: no-repeat;

      @include media-breakpoint-down(sm) {
        border: none;
      }
    }
  }

  figure {
    transition: all .5s ease;
    figcaption {
      font-size: $font-size-base*0.66;
      z-index: 10;
      text-align: right;
      margin-right: 5px;
      margin-top: 2px;
      display: none;
      @include media-breakpoint-down(sm) {
        position: absolute;
        bottom: -25px;
      }
    }
  }
}

.vignette {
  &::before {
    bottom: 0;
    box-shadow: 0 0 200px rgba(0, 0, 0, 0.9) inset;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
  &.vignette-light {
    &::before {
      opacity: 0.2;
    }
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3), 0 0 200px rgba(0, 0, 0, 0.33) inset;
  }
}

// https://www.w3schools.com/howto/howto_css_flip_card.asp
// *******************************************************
// TODO: improve it with a box: https://3dtransforms.desandro.com/box

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  perspective: 1500px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: all .5s ease-in-out;
  transform-style: preserve-3d;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  perspective: 1000px;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flip .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  width: 100%;
  height: inherit;
  backface-visibility: hidden;
}

/* Style the back side */
.flip-card-front {
  z-index: 900;
}

/* Style the back side */
.flip-card-back {
  position: absolute;
  top: 0;
  height: inherit;
  border-radius: 2px;
  transform: rotateY(180deg);
  z-index: 1000;
  padding: 50px;


  // All except iPhone
  @include media-breakpoint-up(sm) {
    border-top: $frame solid #111;
    border-left: $frame solid #222;
    border-right: $frame solid #222;
    border-bottom: $frame solid #111;
    background-image: url("../images/cardboard.jpg");
    background-repeat: repeat;
    background-size: 300px 300px;
  }

  // iPhone only
  @include media-breakpoint-down(sm) {
    border: 3px solid #111;
    padding: 0;
    background-image: none;
    background-color: #333;
    &::before {
      box-shadow: none;
    }
  }

  // Clips
  .clip {
    position: absolute;
    width: 2%;
    min-width: 10px;
    height: 1%;
    min-height: 5px;
    background-color: #000;
    box-shadow: -3px 3px 10px 0px rgba(0,0,0,0.25);
    background: linear-gradient(to right, rgba(10,10,10,1) 0%, rgba(20,20,20,1) 100%);
    @include media-breakpoint-down(sm) {
      display: none;
    }

    &.clip-horizontal {
      &.clip-top {
        top: 32%;
      }
      &.clip-bottom {
        top: 66%;
      }
      &.clip-left {
        left: 0px;
      }
      &.clip-right {
        right: 0px;
      }
    }
    &.clip-vertical {
      transform: rotate(90deg);
      &.clip-top {
        left: 32%;
      }
      &.clip-bottom {
        left: 66%;
      }
      &.clip-left {
        top: 0px;
      }
      &.clip-right {
        bottom: 0px;
      }
    }
  }

  // hangers
  .hanger {
    width: 50px;
    position: absolute;
    top: 10px;
    svg,
    img {
      filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
    &.hanger-left {
      left: 5%;
      right: auto;
    }
    &.hanger-right {
      right: 5%;
      left: auto;
    }
  }

}
