@charset "UTF-8";
/* VENDOR - Default bootstrap
========================================================================== */
/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */
@import url("https://fonts.googleapis.com/css?family=Lacquer|Open+Sans+Condensed:300&display=swap");
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid !important;
  align-items: center; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

:root {
  --blinking-delay: 4s; }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans Condensed", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f0f0f0; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #c1272d;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #811a1e;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* BASE - Base Variable file along with starting point Mixins and Placeholders.
========================================================================== */
@keyframes bounceRotation {
  0%, 100% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(5deg); } }

@keyframes bounceLeft {
  0%, 100% {
    transform: translateX(0px); }
  50% {
    transform: translateX(-10px); } }

/* FRAMEWORK - Structure and layout files.
========================================================================== */
html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  min-height: 100%;
  padding-bottom: 6rem;
  position: relative; }
  @media (min-width: 576px) {
    body {
      background-image: url("../images/content-texture100.jpg");
      background-repeat: repeat; } }

main::after {
  display: block;
  clear: both;
  content: ""; }

.intro {
  padding: 2em 2.5% 70px 2.5%;
  text-align: center;
  min-height: 60px;
  position: relative;
  overflow-x: hidden;
  z-index: 1; }
  .intro p {
    font-size: 22px; }
  @media (max-width: 575.98px) {
    .intro {
      padding-top: 50px;
      padding-bottom: 50px; } }

.container {
  width: 90%; }

nav {
  font-family: 'DIN Condensed Bold';
  text-transform: uppercase; }
  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    nav ul li {
      display: inline-block;
      margin: 0 0.5em; }
  @media (max-width: 575.98px) {
    nav {
      width: 100%;
      padding: 20px 0 15px; } }

header {
  padding: 10px 20px 1px;
  text-align: center;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e2e2e2; }
  header::after {
    display: block;
    clear: both;
    content: ""; }
  @media (min-width: 768px) {
    header.sticky {
      position: fixed;
      top: 0;
      z-index: 100; } }
  @media (min-width: 768px) {
    header + main {
      padding-top: 72px; } }
  header #utilities {
    width: 200px;
    text-align: right;
    display: block;
    float: right; }
    @media (max-width: 767.98px) {
      header #utilities {
        display: none; } }
    header #utilities ul li {
      display: inline-block; }
      header #utilities ul li img {
        width: 30px;
        height: 30px; }
  header nav#main {
    text-align: center;
    font-size: 2.2rem;
    margin-left: 220px;
    margin-right: 110px;
    margin-bottom: -6px;
    padding-top: 6px; }
    @media (max-width: 767.98px) {
      header nav#main {
        margin-right: 0;
        text-align: right; } }
    @media (max-width: 575.98px) {
      header nav#main {
        font-size: 2rem;
        text-align: center;
        margin: 0;
        padding: 0;
        width: 100%; }
        header nav#main ul > li {
          margin: 10px 5px 0px; } }
    header nav#main a {
      position: relative;
      display: inline; }
      header nav#main a::after {
        width: 50px;
        height: 50px;
        top: 36%;
        left: 50%;
        border-width: 5px; }
      @media (max-width: 575.98px) {
        header nav#main a {
          padding: 0 0.1em; } }

.menu ul li {
  position: relative; }

.menu .help-text,
.menu .help-text-active {
  font-size: 0.9em;
  bottom: -20px;
  position: absolute;
  opacity: 0;
  text-align: right;
  right: -3px;
  width: 200px;
  transition: all .25s ease-out; }
  .menu .help-text.show,
  .menu .help-text-active.show {
    opacity: 1;
    bottom: -25px; }

footer {
  background-color: #fff;
  border-top: 1px solid #e2e2e2;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  padding: 0.5rem 1rem; }
  footer::after {
    display: block;
    clear: both;
    content: ""; }
  @media (min-width: 576px) {
    footer {
      padding: 1rem 1rem 0.6rem; } }
  footer nav a:after {
    width: 35px;
    height: 35px;
    top: 35%; }
  footer nav#info {
    float: left; }
  footer nav#social {
    float: right; }

.page-default header#navigation div#utilities ul li {
  display: none; }

.page-default .intro {
  padding: 2em 2.5% 20px 2.5%; }

.page-default main {
  margin-bottom: 5em; }
  .page-default main img {
    width: 100%;
    margin-bottom: 1em;
    border-radius: 5px;
    border: 2px solid white;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25); }

.page-home .intro {
  padding-bottom: 40px; }
  @media (max-width: 575.98px) {
    .page-home .intro {
      margin-bottom: 0px;
      padding-top: 0px; } }

@media (max-width: 575.98px) {
  .page-home .albums .album-container {
    margin-bottom: 50px; } }

form abbr[title] {
  text-decoration: none; }

form label,
form input,
form textarea,
form select {
  text-align: left;
  width: 100%; }

form label {
  margin-left: 16px;
  margin-bottom: 0;
  font-weight: bold;
  width: 100%;
  text-align: left; }

form input,
form textarea,
form select {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 1em;
  padding: 10px 15px;
  border: 1px solid lightgray; }
  form input:focus,
  form textarea:focus,
  form select:focus {
    background-color: #fbecec;
    outline-color: #c1272d; }

form textarea {
  height: 200px; }

form select {
  height: 52px; }

form button {
  background-color: #c1272d;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 4px;
  width: 100px;
  border: 2px solid #971e23; }
  form button:hover {
    cursor: pointer;
    background-color: #971e23;
    border: 2px solid #6c1619; }
  form button:disabled {
    background-color: darkgray; }
    form button:disabled:hover {
      cursor: not-allowed; }

.alert {
  font-size: 20px;
  border: 3px solid lightgray;
  padding: 10px 15px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 30px;
  font-weight: bold; }
  .alert.success {
    color: #008744;
    border-color: #008744; }
  .alert.error {
    color: #d33a2c;
    border-color: #d33a2c;
    background-color: #fbebea; }

.honeypot {
  position: absolute;
  left: -9999px; }

/* MODULES - Re-usable site elements.
========================================================================== */
@font-face {
  font-display: swap;
  font-family: 'DIN Condensed Bold';
  font-style: normal;
  font-weight: bold;
  src: local("DIN Condensed Bold"), url("../fonts/DINCondensed-Bold.woff") format("woff"); }

h1, h2, h3, h4, h5, h6 {
  text-align: center;
  font-family: 'DIN Condensed Bold';
  text-transform: uppercase; }

h1, .h1 {
  font-size: 4.25rem;
  margin-top: 2rem;
  line-height: 2rem; }
  @media (max-width: 767.98px) {
    h1, .h1 {
      font-size: 2.5rem; } }
  h1 a::after, .h1 a::after {
    width: 80px;
    height: 80px;
    top: 33%;
    border-width: 9px; }

h2, .h2 {
  font-size: 3.2rem; }
  @media (max-width: 767.98px) {
    h2, .h2 {
      font-size: 2rem; } }
  h2 a::after, .h2 a::after {
    width: 70px;
    height: 70px;
    top: 33%;
    border-width: 7px; }

h3, .h3 {
  margin-top: 5rem;
  margin-bottom: 2rem;
  overflow: hidden;
  font-size: 2.625rem; }
  @media (max-width: 767.98px) {
    h3, .h3 {
      font-size: 1.75rem; } }
  h3::before, h3::after, .h3::before, .h3::after {
    background-color: #212529;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: super;
    width: 50%; }
  h3::before, .h3::before {
    right: 0.33em;
    margin-left: -50%; }
  h3::after, .h3::after {
    left: 0.33em;
    margin-right: -50%; }

h4, .h4 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 1.95rem; }
  @media (max-width: 767.98px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  margin-top: 2.5rem;
  margin-bottom: 0rem;
  font-size: 1.5625rem; }
  @media (max-width: 767.98px) {
    h5, .h5 {
      font-size: 1.25rem; } }

h6, .h6 {
  margin-top: 2rem;
  margin-bottom: 0rem;
  font-size: 1.2rem; }
  @media (max-width: 767.98px) {
    h6, .h6 {
      font-size: 1rem; } }

a,
button {
  transition: all 0.25s ease-in-out; }

a {
  position: relative;
  display: inline; }
  a::after {
    width: 30px;
    height: 30px;
    transform: translateX(-50%) translateY(-50%) scale(0.8) rotate(8deg);
    position: absolute;
    top: 50%;
    left: 50%;
    border-width: 5px;
    border-color: #c1272d;
    border-style: solid;
    content: '';
    opacity: 0;
    transition: all 0.25s ease-in-out; }
  a:hover, a[aria-current="page"] {
    outline: none;
    text-decoration: none;
    color: #971e23; }
    a:hover::after, a[aria-current="page"]::after {
      transition: all 0.25s ease-in-out;
      opacity: 0.2;
      transform: translateX(-50%) translateY(-50%) scale(1) rotate(-8deg);
      animation-duration: .25s;
      animation-fill-mode: both;
      animation-timing-function: linear; }
  a[aria-current="page"]:hover::after {
    animation-name: bounceLink; }

@keyframes bounceLink {
  0%, 100% {
    transform: translateX(-50%) translateY(-50%) scale(1) rotate(-8deg); }
  50% {
    transform: translateX(-50%) translateY(-50%) scale(1) rotate(-16deg); } }

.text-center {
  text-align: center; }

abbr[title].asterisk,
abbr[data-original-title].asterisk {
  text-decoration: none;
  color: #c1272d; }

abbr[title] {
  position: relative; }
  abbr[title]:hover, abbr[title]:focus {
    outline-style: none; }
    abbr[title]:hover::after, abbr[title]:focus::after {
      text-transform: initial;
      font-family: "Open Sans Condensed", sans-serif;
      content: attr(title);
      /* position tooltip like the native one */
      position: absolute;
      transform: translateX(-50%);
      top: -2.2rem;
      width: auto;
      white-space: nowrap;
      /* style tooltip */
      background-color: #212529;
      color: #fff;
      border-radius: 4px;
      box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
      font-size: 1rem;
      padding: 0 7px;
      line-height: 1.8rem; }

figure {
  margin: 0; }
  figure figcaption {
    text-align: center; }

.albums {
  margin-top: -100px;
  position: relative; }
  @media (max-width: 767.98px) {
    .albums {
      margin-top: -130px; } }
  .albums .album-title {
    margin-top: 20px;
    position: absolute;
    width: 100%;
    text-align: center; }
    @media (max-width: 767.98px) {
      .albums .album-title {
        margin-top: 40px;
        padding: 0 50px; } }
  .albums .album-container {
    position: relative;
    margin-bottom: 10px; }
    @media (max-width: 575.98px) {
      .albums .album-container {
        margin-bottom: 0px; } }
    .albums .album-container .next-album {
      z-index: 10;
      text-align: center;
      width: 100%;
      position: absolute;
      top: -400px; }

.photo {
  position: relative;
  transition-property: width, height;
  transition-duration: .5s;
  transition-timing-function: ease;
  z-index: 2;
  margin-right: 10%;
  margin-top: 90px;
  margin-bottom: 90px;
  max-width: 800px; }
  .photo.portrait {
    width: 40%;
    max-width: 600px; }
    @media (max-width: 991.98px) {
      .photo.portrait {
        width: 50%; } }
  .photo.landscape {
    width: 60%;
    max-width: 1000px; }
    @media (max-width: 991.98px) {
      .photo.landscape {
        width: 75%; } }
    @media (max-width: 575.98px) {
      .photo.landscape .photo-caption {
        display: none; } }
  @media (max-width: 767.98px) {
    .photo.portrait, .photo.landscape {
      width: 95%; } }
  .photo .photo-sticker {
    display: block;
    float: left;
    text-align: left;
    position: absolute; }
    .photo .photo-sticker::after {
      display: block;
      clear: both;
      content: ""; }
  .photo .photo-logo {
    float: none; }
    .photo .photo-logo::after {
      display: block;
      clear: both;
      content: ""; }
  .photo .signature {
    margin-top: 5px; }
    .photo .signature img {
      width: 50%; }
  .photo .photo-info {
    padding: 20px;
    line-height: 1.5rem;
    text-align: center; }
    @media (min-width: 768px) {
      .photo .photo-info {
        background-color: rgba(240, 240, 240, 0.9);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        left: 50%;
        transform: translateX(-50%) rotate(0deg) scale(1);
        max-width: 50%; } }
    @media (max-width: 767.98px) {
      .photo .photo-info {
        width: 100%;
        color: #fff;
        padding: 30px; } }
    .photo .photo-info .photo-title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 0.5em; }
    .photo .photo-info .photo-caption {
      font-size: 18px; }
  .photo .photo-metadata {
    border-radius: 2px;
    color: white;
    bottom: 5px;
    padding: 5px 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    max-width: 300px; }
    @media (min-width: 768px) {
      .photo .photo-metadata {
        background-color: rgba(240, 240, 240, 0.9);
        color: #212529;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25); } }
    .photo .photo-metadata .photo-number {
      font-weight: bold;
      float: right;
      font-size: 12px; }
  .photo .photo-location {
    font-weight: bold;
    font-size: 15px;
    margin: 0;
    padding: 0; }
    @media (max-width: 575.98px) {
      .photo .photo-location {
        font-size: 13.2px; } }
  .photo ul.photo-exif {
    margin-bottom: 0;
    opacity: .5;
    font-size: 11px;
    padding-left: 0; }
    .photo ul.photo-exif .photo-date {
      font-weight: bold; }
    .photo ul.photo-exif li {
      display: inline-block; }
      .photo ul.photo-exif li:not(:first-child)::before {
        content: ' • '; }
  .photo.is-selected .frame {
    cursor: initial;
    cursor: -webkit-grab;
    cursor: grab; }
  .photo.is-selected .icon-flip,
  .photo.is-selected .icon-like {
    opacity: 0.25; }
  .photo.is-selected .icon-like.like {
    opacity: 1; }
  .photo .frame-zoom .icon {
    stroke: white; }
    .photo .frame-zoom .icon svg,
    .photo .frame-zoom .icon svg path#contour {
      stroke: white;
      fill: white; }
    .photo .frame-zoom .icon .help-text {
      color: white; }

.frame {
  cursor: pointer;
  transition: all .5s ease;
  border-top: 15px solid #111;
  border-left: 15px solid #222;
  border-right: 15px solid #222;
  border-bottom: 15px solid #111;
  border-radius: 2px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  top: 0;
  position: relative;
  z-index: 2; }
  @media (max-width: 767.98px) {
    .frame {
      border: 3px solid #111; } }
  .frame img {
    height: auto;
    width: 100%; }
  .frame .canvas {
    background-color: #fff;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.5);
    padding: 12.5%; }
    @media (max-width: 767.98px) {
      .frame .canvas {
        padding: 0; } }
    .frame .canvas img {
      border-top: 2px solid #ccc;
      border-left: 2px solid #ccc;
      border-right: 2px solid #ddd;
      border-bottom: 2px solid #ddd;
      background-color: #b9b9b9;
      background-size: 243px 113px;
      background-position: center;
      background-repeat: no-repeat; }
      @media (max-width: 767.98px) {
        .frame .canvas img {
          border: none; } }
  .frame figure {
    transition: all .5s ease; }
    .frame figure figcaption {
      font-size: 13.2px;
      z-index: 10;
      text-align: right;
      margin-right: 5px;
      margin-top: 2px;
      display: none; }
      @media (max-width: 767.98px) {
        .frame figure figcaption {
          position: absolute;
          bottom: -25px; } }

.vignette::before {
  bottom: 0;
  box-shadow: 0 0 200px rgba(0, 0, 0, 0.9) inset;
  content: '';
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.vignette.vignette-light {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3), 0 0 200px rgba(0, 0, 0, 0.33) inset; }
  .vignette.vignette-light::before {
    opacity: 0.2; }

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  perspective: 1500px;
  /* Remove this if you don't want the 3D effect */ }

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: all .5s ease-in-out;
  transform-style: preserve-3d;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  perspective: 1000px; }

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flip .flip-card-inner {
  transform: rotateY(180deg); }

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  width: 100%;
  height: inherit;
  backface-visibility: hidden; }

/* Style the back side */
.flip-card-front {
  z-index: 900; }

/* Style the back side */
.flip-card-back {
  position: absolute;
  top: 0;
  height: inherit;
  border-radius: 2px;
  transform: rotateY(180deg);
  z-index: 1000;
  padding: 50px; }
  @media (min-width: 576px) {
    .flip-card-back {
      border-top: 15px solid #111;
      border-left: 15px solid #222;
      border-right: 15px solid #222;
      border-bottom: 15px solid #111;
      background-image: url("../images/cardboard.jpg");
      background-repeat: repeat;
      background-size: 300px 300px; } }
  @media (max-width: 767.98px) {
    .flip-card-back {
      border: 3px solid #111;
      padding: 0;
      background-image: none;
      background-color: #333; }
      .flip-card-back::before {
        box-shadow: none; } }
  .flip-card-back .clip {
    position: absolute;
    width: 2%;
    min-width: 10px;
    height: 1%;
    min-height: 5px;
    background-color: #000;
    box-shadow: -3px 3px 10px 0px rgba(0, 0, 0, 0.25);
    background: linear-gradient(to right, #0a0a0a 0%, #141414 100%); }
    @media (max-width: 767.98px) {
      .flip-card-back .clip {
        display: none; } }
    .flip-card-back .clip.clip-horizontal.clip-top {
      top: 32%; }
    .flip-card-back .clip.clip-horizontal.clip-bottom {
      top: 66%; }
    .flip-card-back .clip.clip-horizontal.clip-left {
      left: 0px; }
    .flip-card-back .clip.clip-horizontal.clip-right {
      right: 0px; }
    .flip-card-back .clip.clip-vertical {
      transform: rotate(90deg); }
      .flip-card-back .clip.clip-vertical.clip-top {
        left: 32%; }
      .flip-card-back .clip.clip-vertical.clip-bottom {
        left: 66%; }
      .flip-card-back .clip.clip-vertical.clip-left {
        top: 0px; }
      .flip-card-back .clip.clip-vertical.clip-right {
        bottom: 0px; }
  .flip-card-back .hanger {
    width: 50px;
    position: absolute;
    top: 10px; }
    .flip-card-back .hanger svg,
    .flip-card-back .hanger img {
      filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3)); }
    @media (max-width: 767.98px) {
      .flip-card-back .hanger {
        display: none; } }
    .flip-card-back .hanger.hanger-left {
      left: 5%;
      right: auto; }
    .flip-card-back .hanger.hanger-right {
      right: 5%;
      left: auto; }

button.icon,
.icon button {
  border: none;
  background-color: transparent; }
  button.icon:focus, button.icon:active, button.icon:visited,
  .icon button:focus,
  .icon button:active,
  .icon button:visited {
    border: none;
    outline: none; }

.icon {
  margin: 5px; }
  .icon svg {
    width: 24px; }
  .icon:hover {
    cursor: pointer; }
  .icon.icon-like, .icon.icon-flip {
    position: absolute;
    padding-bottom: 3px;
    bottom: 0; }
    @media (max-width: 767.98px) {
      .icon.icon-like, .icon.icon-flip {
        fill: white; } }
  .icon.icon-like {
    opacity: 0;
    left: 0;
    padding-left: 5px; }
    .icon.icon-like svg {
      transition: all 0.25s ease-in-out; }
    .icon.icon-like:hover {
      opacity: 1; }
      .icon.icon-like:hover svg {
        transform: scale(1.25); }
      .icon.icon-like:hover .help-text {
        opacity: 1;
        left: 40px; }
        @media (max-width: 767.98px) {
          .icon.icon-like:hover .help-text {
            color: white; } }
    .icon.icon-like svg path#contour {
      fill: white;
      stroke: #212529;
      stroke-width: 30;
      stroke-miterlimit: 10; }
    .icon.icon-like.like svg path#contour {
      fill: #c1272d;
      stroke: #c1272d;
      stroke-width: 30;
      stroke-miterlimit: 10; }
    .icon.icon-like.like .help-text {
      display: none; }
    .icon.icon-like .help-text {
      font-size: 0.9em;
      left: 50px;
      bottom: 0;
      position: absolute;
      opacity: 0;
      text-align: left;
      width: 70px;
      transition: all .25s ease-out; }
  .icon.icon-flip {
    opacity: 0;
    right: 0;
    padding-right: 5px; }
    .icon.icon-flip svg {
      transform: rotate(0deg);
      transform-origin: 50% 50%;
      transition: all 0.25s ease-in-out; }
    .icon.icon-flip:hover {
      opacity: 1; }
      .icon.icon-flip:hover svg {
        transform: rotate(180deg); }
      .icon.icon-flip:hover .help-text {
        opacity: 1;
        right: 35px; }
        @media (max-width: 767.98px) {
          .icon.icon-flip:hover .help-text {
            color: white; } }
    .icon.icon-flip .help-text {
      font-size: 0.9em;
      right: 45px;
      bottom: 3px;
      position: absolute;
      opacity: 0;
      text-align: right;
      width: 70px;
      transition: all .25s ease-out; }
  .icon.icon-fullscreen svg {
    transition: all 0.25s ease-in-out;
    transform: scale(1); }
    .icon.icon-fullscreen svg:hover {
      transform: scale(1.1); }
  .icon.icon-fullscreen.is-fullscreen svg:hover {
    transform: scale(0.9); }
  .icon.icon-zoom svg #image {
    transition: all 0.25s ease-in-out;
    transform-origin: 50% 50%;
    transform: scale(1); }
  .icon.icon-zoom:hover svg #image, .icon.icon-zoom.active svg #image {
    transform: scale(1.75); }
  .icon.icon-zoom:hover.active svg #image {
    transform: scale(1); }

.logo {
  display: flex;
  float: left;
  position: relative; }
  .logo a:hover::after {
    display: none; }
  .logo a:hover .lastname {
    color: black; }
  .logo a:hover .firstname {
    color: #c1272d; }
  .logo a:hover .square {
    transform: rotate(-100deg); }
  .logo svg {
    float: left;
    height: 50px;
    padding-right: 10px;
    z-index: 1;
    position: relative; }
  .logo .firstname,
  .logo .lastname,
  .logo .domain {
    font-family: 'DIN Condensed Bold'; }
  .logo .firstname,
  .logo .lastname {
    float: left;
    font-size: 2.5rem;
    text-transform: lowercase;
    transition: all .25s; }
  .logo .firstname {
    color: black; }
  .logo .lastname {
    color: #c1272d; }
  .logo .domain {
    text-transform: uppercase;
    font-size: 1rem;
    position: absolute;
    left: 68px;
    top: 39px;
    color: black; }
  .logo .square {
    width: 50px;
    height: 50px;
    border: 5px solid #c1272d;
    position: absolute;
    left: 0px;
    z-index: 0;
    transform: rotate(-10deg);
    transition: all 0.25s ease-in-out; }
  @media (max-width: 575.98px) {
    .logo {
      align-items: center;
      justify-content: center;
      float: none; }
      .logo > a::after {
        display: block;
        clear: both;
        content: ""; } }
  .logo.logo-sm .firstname,
  .logo.logo-sm .lastname {
    font-size: 1.3rem; }
  .logo.logo-sm .domain {
    font-size: 0.45rem;
    left: 38px;
    top: 21px; }
  .logo.logo-sm .square {
    width: 25px;
    height: 25px;
    border: 3px solid #c1272d; }
  .logo.logo-sm svg,
  .logo.logo-sm img {
    height: 25px;
    padding-right: 5px;
    z-index: 1; }

@keyframes eyesClosed {
  0%, 48%, 52% {
    transform: scaleY(1); }
  50% {
    transform: scaleY(0.5); } }

.logo svg #eyes {
  animation-name: eyesClosed;
  animation-duration: 4s;
  animation-delay: var(--blinking-delay);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: center 41%; }

.flickity-enabled.is-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding-bottom: 0;
  z-index: 1; }

.flickity-enabled.is-fullscreen .flickity-page-dots {
  bottom: 10px; }

.flickity-enabled.is-fullscreen .flickity-page-dots .dot {
  background: white; }

/* prevent page scrolling when flickity is fullscreen */
html.is-flickity-fullscreen {
  overflow: hidden; }

/* ---- flickity-fullscreen-button ---- */
.flickity-fullscreen-button {
  display: block;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: 4px; }

.flickity-fullscreen-button {
  margin-top: 25px; }
  @media (max-width: 767.98px) {
    .flickity-fullscreen-button {
      display: none !important; } }

/* right-to-left */
.flickity-rtl .flickity-fullscreen-button {
  right: auto;
  left: 10px; }

.flickity-fullscreen-button-exit {
  display: none; }

.flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit {
  display: block; }

.flickity-enabled.is-fullscreen .flickity-fullscreen-button-view {
  display: none; }

.flickity-fullscreen-button .flickity-button-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 4px;
  top: 4px; }

.flickity-viewport {
  transition: height 0.2s; }

.is-fullscreen {
  width: 100%; }
  .is-fullscreen .photo {
    height: 100%;
    /* center images in cells with flexbox */
    display: flex;
    max-width: none !important;
    align-items: center;
    justify-content: center;
    margin-top: 90px; }
    .is-fullscreen .photo img {
      display: block;
      max-height: 100%; }
    .is-fullscreen .photo .flip-card-inner {
      box-shadow: none; }
    .is-fullscreen .photo .frame {
      padding: 0;
      border: none;
      box-shadow: none; }
      .is-fullscreen .photo .frame .canvas {
        padding: 0;
        background-color: transparent;
        color: #fff; }

@media (min-width: 768px) {
  .flickity-prev-next-button {
    display: none; } }

.flickity-prev-next-button {
  top: 46%;
  -webkit-transform: none;
  transform: none;
  width: 40px;
  height: 60px;
  border-radius: 5px;
  color: white; }

.flickity-prev-next-button.previous {
  right: auto;
  left: 12px; }

.flickity-prev-next-button.next {
  left: auto;
  right: 12px; }

.flickity-prev-next-button .flickity-button-icon {
  left: 25%;
  top: 25%;
  width: 50%;
  height: 50%; }

.flickity-button {
  background: transparent; }
  .flickity-button:disabled {
    opacity: 0; }
  .flickity-button:hover {
    background: transparent; }

.album-selected {
  counter-reset: photo; }

.photo-number {
  counter-increment: photo; }
  .photo-number:before {
    content: counter(photo); }

.flickity-page-dots {
  bottom: 10px;
  z-index: 1;
  height: 5px;
  opacity: 0; }
  .flickity-page-dots:hover {
    bottom: 0;
    height: 15px; }
  .flickity-page-dots:hover .dot {
    height: 15px; }
    .flickity-page-dots:hover .dot:before {
      top: 4px; }

/* dots are lines */
.flickity-page-dots .dot {
  height: 7px;
  width: 2.5%;
  margin: 0;
  border-radius: 0;
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: ease;
  background: #c1272d; }
  .flickity-page-dots .dot:hover {
    opacity: 0.5; }
  .flickity-page-dots .dot.is-selected:hover, .flickity-page-dots .dot.is-selected {
    opacity: 0.8; }

.album-selected .flickity-page-dots {
  position: fixed;
  opacity: 1;
  width: 100%; }

.paper {
  position: relative;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin: 10px auto;
  border: 1px solid #eaeaea; }
  .paper::before, .paper::after {
    content: '';
    position: absolute;
    bottom: 10px;
    width: 40%;
    height: 10px;
    box-shadow: 0 5px 14px rgba(0, 0, 0, 0.7);
    z-index: -1;
    transition: all .3s ease-in-out; }
  .paper::before {
    left: 15px;
    transform: skew(-5deg) rotate(-5deg); }
  .paper::after {
    right: 15px;
    transform: skew(5deg) rotate(5deg);
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.4); }
  .paper:hover::before {
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.4); }
  .paper:hover::before {
    left: 5px; }
  .paper:hover::after {
    right: 5px; }

.hint {
  text-align: left;
  padding: 10px;
  font-size: 20px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  background-color: #fff;
  width: 300px;
  min-height: 80px;
  margin: 0 auto;
  position: absolute;
  right: -225px;
  top: 10px;
  transition: right .5s ease; }
  .hint.show {
    right: -20px; }
  .hint .hint-text {
    margin-left: 70px;
    margin-right: 20px; }
    .hint .hint-text p {
      margin-bottom: 0; }
  .hint .closeHint {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: none;
    color: lightgray; }
  .hint .bulb {
    position: absolute;
    width: 60px;
    padding: 0 10px; }
  .hint:hover {
    cursor: pointer; }
    .hint:hover .closeHint {
      color: #c1272d; }
    .hint:hover svg {
      transition: all 0.25s ease-in-out;
      animation-name: bounceRotation;
      animation-duration: .25s;
      animation-fill-mode: both;
      animation-timing-function: linear; }
      .hint:hover svg .b {
        fill: #e9f15f; }
