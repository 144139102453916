form {
  abbr[title] {
    text-decoration: none;
  }
  label,
  input,
  textarea,
  select {
    text-align: left;
    width: 100%;
  }
  label {
    margin-left: 16px;
    margin-bottom: 0;
    font-weight: bold;
    width: 100%;
    text-align: left;
  }
  input,
  textarea,
  select {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 1em;
    padding: 10px 15px;
    border: 1px solid lightgray;
    &:focus {
      background-color: lighten($link-color,50%);
      outline-color: $link-color;
    }
  }
  textarea {
    height: 200px;
  }
  select {
    height: 52px;
  }
  button {
    background-color: $link-color;
    color: white;
    padding: 5px 10px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px;
    width: 100px;
    border: 2px solid darken($link-color,10%);
    &:hover {
      cursor: pointer;
      background-color: darken($link-color,10%);
      border: 2px solid darken($link-color,20%);
    }
    &:disabled {
      background-color: darkgray;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

// alert messages
.alert {
  font-size: $font-size-base;
  border: 3px solid lightgray;
  padding: 10px 15px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 30px;
  font-weight: bold;
  &.success {
    color: $color-success;
    border-color: $color-success;
  }
  &.error {
    color: $color-danger;
    border-color: $color-danger;
    background-color: lighten($color-danger,45%);
  }
}

.honeypot {
  position: absolute;
  left: -9999px;
}

// FORM
form {
  
}